<template>
  <div :style="{ 'max-height': isShow === 2 ? '204px' : 15 * size + 'px'}"
       class="virtual-list">
    <!-- <section class="scroll-list"
               ref="scrollListRef">
        <div class="scroll-container"
             ref="scrollContainerRef"> -->
    <section class="draggable-tree">
      <a-directory-tree v-if="isShow === 1"
                        :tree-data="renderList"
                        :default-expanded-keys="[]"
                        @expand="onExpand"
                        @check="onCheck"
                        show-icon
                        :replaceFields="{ children: 'departmentDTOList', title: 'departmentName', key: 'id' }">
        <template #switcherIcon>
          <down-outlined />
        </template>
        <template #bumen><span class="bumen"><i class="iconfont icon icon-bumen"></i></span></template>
      </a-directory-tree>
    </section>
    <section class="draggable-tree2">
      <a-tree v-if="isShow === 2"
              checkable
              :checkedKeys="department"
              :tree-data="renderList"
              :expanded-keys="expandedKeys"
              :default-expanded-keys="['sss']"
              :auto-expand-parent="autoExpandParent"
              @expand="onExpand"
              @check="onCheck"
              :replaceFields="{ children: 'departmentDTOList', title: 'departmentName', key: 'id' }">
        <!-- <template #switcherIcon>
            <img src="@/assets/svg/depart-tree-arrow.svg" />
            <CaretDownOutlined v-if="key!='sss'"/>
          </template> -->
      </a-tree>
    </section>
    <!-- </div>
      </section> -->
  </div>
</template>

<script>
import { reactive, toRefs, ref, watch, computed } from 'vue'
import { throttle, flatLevelArrayProperty } from '@/utils/utils'
import { DownOutlined, CaretDownOutlined } from '@ant-design/icons-vue'
// import { useStore } from 'vuex'
export default {
  name: 'tree-virtual-list',
  components: {
    DownOutlined,
    CaretDownOutlined,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    size: {
      type: Number,
      default: 51,
    },
    remain: {
      type: Number,
      default: 10,
    },
    isShow: {
      type: Number,
      default: 1,
    },
    department: {
      type: Array,
      default: () => [],
    },
    isReload: {
      default: () => ({ type: '+' }),
    },
  },
  setup(props, { emit }) {
    const virtualListRef = ref(null)
    const scrollListRef = ref(null)
    const scrollContainerRef = ref(null)
    const prevCount = computed(() => Math.min(state.start, props.remain))
    const nextCount = computed(() => Math.min(props.list.length - state.start, props.remain))
    // const store = useStore()
    // const enterpriseName = computed(() => store.state.user.userInfo.enterpriseName)
    const state = reactive({
      selectList: [],
      renderList: [],
      start: 0,
      end: props.remain * 2,
      prevTransVal: 0,
      departMap: new Map(),
      departRenderMap: new Map(), // 渲染的列表状态
      expandedKeys: [],
      autoExpandParent: false,
      departmentAll: props.department, // 存储所有部门，防止部门没有渲染时的报错
      department: [],
    })

    const getStartIndex = (value) => {
      return parseInt(value / props.size)
    }

    const setRenderList = () => {
      if (props.isShow === 1) {
        state.renderList = props.list.slice(state.start - prevCount.value, state.start + 8 + nextCount.value)
        // 添加icon
        state.renderList.forEach((item) => {
          item.slots = { icon: 'bumen' }
        })
      } else if (props.isShow === 2) {
        const list = props.list.slice(state.start - prevCount.value, state.start + 8 + nextCount.value)
        state.renderList = [
          {
            departmentDTOList: list,
            departmentName: '全部部门',
            id: 'sss',
          },
        ]
        state.departRenderMap.clear()
        deepIteration(state.renderList)
      }
      state.departRenderMap.forEach((item, key) => {
        item && state.department.push(key)
      })
      function deepIteration(array) {
        array.forEach((item) => {
          if (item.children && item.children.length) {
            deepIteration(item.children)
          }
          if (state.departMap.get(item.key)) {
            state.departRenderMap.set(item.key, true)
          }
        })
      }
    }

    const handleListScroll = throttle(() => {
      state.start = getStartIndex(top)
      setRenderList()
      const transVal = ~~(top - prevCount.value * props.size)
      state.prevTransVal = props.isShow === 2 ? transVal + props.size : transVal
      // scrollContainerRef.value.style.transform = `translateY(${transVal}px)`
    }, 10)

    const onExpand = (e, { expanded, node }) => {
      console.log(node.children)
      // if (expanded) {
      //   scrollListRef.value.style.height =
      //     ~~scrollListRef.value.style.height.replace('px', '') + node.children.length * props.size + 'px'
      // } else {
      //   scrollListRef.value.style.height =
      //     ~~scrollListRef.value.style.height.replace('px', '') - node.children.length * props.size + 'px'
      // }
      state.expandedKeys = e
      state.autoExpandParent = false
    }

    const onCheck = (checkedKeys, { checked, node }) => {
      // const result = flatLevelArrayProperty(node.dataRef, 'id')
      // console.log(checkedKeys)
      // const iterationSetMap = (map, val) => {
      //   for (let [key] of map) {
      //     state.departMap.set(key, val)
      //   }
      // }
      // if (checked) {
      //   if (node.eventKey === 'sss') {
      //     iterationSetMap(state.departMap, true)
      //     iterationSetMap(state.departRenderMap, true)
      //   } else {
      //     result.forEach(item => {
      //       state.departMap.set(item, true)
      //       state.departRenderMap.set(item, true)
      //     })
      //   }
      // } else {
      //   if (node.eventKey === 'sss') {
      //     iterationSetMap(state.departMap, false)
      //     iterationSetMap(state.departRenderMap, false)
      //   } else {
      //     result.forEach(item => {
      //       state.departMap.set(item, false)
      //       // node.eventKey !== 'sss' &&
      //       state.departRenderMap.set(item, false)
      //     })
      //     console.log(node.eventKey, state.departRenderMap, state.departMap)
      //   }
      // }
      // state.department = []
      // state.departmentAll = []
      // state.departMap.forEach((item, key) => {
      //   item && state.departmentAll.push(key)
      // })
      // state.departRenderMap.forEach((item, key) => {
      //   item && state.department.push(key)
      // })
      state.department = checkedKeys
      // console.log(checkedKeys, state.department)
      emit('onCheck', state.department)
    }

    watch(
      () => props.list.length,
      (newListLen) => {
        setTimeout(() => {
          // if (scrollListRef.value) {
          //   scrollListRef.value.style.height = (props.isShow === 2 ? 1 + newListLen : newListLen) * props.size + 'px'
          //   scrollContainerRef.value.style.transform = `translateY(0px)`
          //   state.start = 0
          //   state.end = props.remain * 2
          //   setRenderList()
          // }
          state.start = 0
          state.end = props.remain * 2
          setRenderList()
        }, 10)

        const isAllDepart = state.departmentAll.includes('sss')
        state.departMap.clear()
        function deepIteration(array) {
          array.forEach((item) => {
            if (item.children && item.children.length) {
              deepIteration(item.children)
            }
            state.departMap.set(item.id, isAllDepart)
          })
        }
        deepIteration(
          props.isShow === 2
            ? [
                {
                  departmentDTOList: props.list,
                  departmentName: '全部部门',
                  id: 'sss',
                },
              ]
            : props.list
        )

        !isAllDepart &&
          props.department.forEach((item) => {
            state.departMap.set(item, true)
          })
        setTimeout(() => {
          state.expandedKeys = ['sss']
        })
      },
      { immediate: true }
    )

    watch(
      () => props.isReload,
      (reload) => {
        setRenderList()
        // switch (reload.type) {
        //   case '+':
        //     scrollListRef.value.style.height = ~~scrollListRef.value.style.height.replace('px', '') + props.size + 'px'
        //     break
        //   case '-':
        //     scrollListRef.value.style.height = ~~scrollListRef.value.style.height.replace('px', '') - props.size + 'px'
        //     break
        //   default:
        //     break
        // }
      }
    )

    return {
      ...toRefs(state),
      virtualListRef,
      scrollListRef,
      scrollContainerRef,
      onExpand,
      onCheck,
      // enterpriseName
    }
  },
}
</script>

<style lang="less" scoped>
.virtual-list {
  overflow-y: auto;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    display: block;
    border-radius: 4px;
    background: #f5f5f5;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #dddddd;
    border-radius: 4px;
  }
}
.draggable-tree {
  .icon {
    font-size: 13px;
    // transform: scale(0.8);
    color: #000000;
    padding: 2px;
  }
  :deep(.ant-tree-node-content-wrapper) {
    padding: 0;
    width: 100%;
  }
  :deep(.ant-tree-title) {
    display: inline-block;
    width: 75%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  // :deep(.ant-tree-node-selected) {
  //   background-color: transparent !important;
  //   .ant-tree-title,.icon{
  //     color: #C3161C;
  //   }
  // }
  :deep(.ant-tree-switcher) {
    float: right;
    transform: rotateZ(90deg);
    margin-top: 8px;
  }
  :deep(.ant-tree-switcher_open) {
    transform: rotateZ(180deg);
  }
  :deep(.ant-tree li ul) {
    padding: 0 !important;
  }
  :deep(.ant-tree li ul li ul .ant-tree-node-content-wrapper) {
    padding-left: 20px;
  }
  // :deep(.ant-tree li:hover){
  //   background-color: #FFF1F0;
  //   cursor: pointer;
  // }
  // :deep(.ant-tree li .ant-tree-node-content-wrapper:hover){
  //   background-color: #FFF1F0 !important;
  // }
  // :deep(.ant-tree li){
  //   padding:0;
  // }
  // :deep(.ant-tree-node-content-wrapper-close),:deep(.ant-tree-node-content-wrapper-open),:deep(.ant-tree-node-content-wrapper-normal){
  //   height: 40px;
  //   line-height: 40px
  // }
  :deep(.anticon) {
    display: none;
    color: rgba(0, 0, 0, 0.65);
  }
  :deep(.ant-tree-child-tree > li:first-child) {
    padding-top: 0;
  }
  :deep(.ant-tree li) {
    padding: 0;
  }
  :deep(.ant-tree-node-selected) {
    .icon,
    .ant-tree-title {
      color: #c3161c;
    }
  }
  .bumen {
    display: inline-block;
    margin: 8px 4px 0 0;
  }
  :deep(.ant-tree-node-content-wrapper::before) {
    display: none;
  }
  :deep(.ant-tree li .ant-tree-node-content-wrapper) {
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
  }
  :deep(.ant-tree-node-content-wrapper:hover) {
    background: #fff1f0 !important;
  }
}

.draggable-tree2 {
  :ddep(.ant-tree-treenode-switcher-open) {
    padding: 0;
  }
  :deep(.ant-tree > li:last-child) {
    padding-bottom: 0;
  }
  :deep(.ant-tree > li:first-child) {
    padding-bottom: 0;
  }
  :deep(.ant-tree > li:first-child > span.ant-tree-switcher) {
    display: none;
  }
  :deep(.ant-tree > li:first-child > span.ant-tree-checkbox) {
    margin-left: 8px;
  }
  // :deep(.ant-tree li ul) {
  //   padding: 0;
  // }
  :deep(.ant-tree-title) {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  :deep(.ant-tree li .ant-tree-node-content-wrapper) {
    width: 75%;
  }
}
</style>
